


























































import { Api } from "@/api";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AreYouSureModal, OrganizationMenu } from "@/components";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { defaultOrganization } from "@/constants";

@Component({
  components: {
    AreYouSureModal,
    OrganizationMenu,
  },
  mixins: [validationMixin],
  validations: {
    name: { required, minLength: minLength(3), maxLength: maxLength(30) },
  },
})
export default class Settings extends Vue {
  @Prop({ type: String, required: true }) id!: string;

  private organization = defaultOrganization;
  private name = "Loading...";
  private network = "Loading...";

  private areYouSureModalVisible = false;
  private loading = true;

  private async mounted(): Promise<void> {
    this.organization = await Api.getOrganizationData(this.id);
    this.name = this.organization.name;
    this.network = this.organization.network;
    this.loading = false;
  }

  private get validName(): boolean | null {
    if (this.$v.$dirty) {
      return this.validateName();
    }
    return null;
  }

  private validateName(): boolean {
    const { $invalid } = this.$v.name;
    if ($invalid) {
      return false;
    }
    return true;
  }

  private async onSaveChangesClick(): Promise<void> {
    this.$v.$touch();
    if (this.validName) {
      this.areYouSureModalVisible = true;
    }
  }

  private async updateSettings(): Promise<void> {
    this.loading = true;
    await Api.updateOrganizationName(this.id, { name: this.name });
    this.areYouSureModalVisible = false;
    this.loading = false;
    this.$v.$reset();
  }
}
